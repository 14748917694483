/** @jsx jsx */
import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import {jsx, css} from "@emotion/core"

import Container from "../../../Container"
import NoResults from "../../../NoResults"

import Search from "../../../../assets/images/search.png"
import Loading from "../../../../assets/images/svg/loading.svg"

import {getChurchList, chooseDestination} from "../../../../state/app"

import {langNavigate} from "../../../../helpers"

import {CampaignImage, Card, CampaignDetails, CardsGrid, CustomButton, Title, SearchButton, SearchInput} from "./styles"

const animation = (transitionStatus, type) => css`
  opacity: 1;
  transition: ease-in-out 500ms;

  ${transitionStatus === "exiting" &&
css`
    ${type === "left" &&
css`
        transform: translateX(-25%);
        opacity: 0;
      `}
    ${type === "right" &&
css`
        transform: translateX(25%);
        opacity: 0;
      `}
    ${type === "fade" &&
css`
        opacity: 0;
      `}
  `}
`

const renderItems = (list, dispatch, translation, exitAnimation, exiting) => {
    if (!list.length) {
        return <NoResults translation={translation}/>
    }

    const donateNow = item => {
        dispatch(chooseDestination(item))
        langNavigate(10, translation.currentLang, exitAnimation, 1000)
    }

    return list.map((item, index) => (
        <Card key={index} exiting={exiting} onClick={() => donateNow(item)}>
            <CampaignImage image={item.image}/>
            <CampaignDetails>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <CustomButton color="blue" uppercase>
                    {translation.donateNow}
                </CustomButton>
            </CampaignDetails>
        </Card>
    ))
}

const List = ({
                  dispatch,
                  translation,
                  exiting,
                  exitAnimation,
                  hasCoordinates,
              }) => {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        dispatch(getChurchList()).then(resp => {
            updateList(resp.data)
        })
    }, [dispatch])

    const updateList = list => {
        setList(
            list.results.map(({imagePath, tradingName, city, id}) => ({
                image: imagePath,
                title: tradingName,
                description: city,
                id,
            }))
        )
        setLoading(false)
    }

    const doSearch = e => {
        if (e) {
            e.preventDefault()
        }
        setLoading(true)

        const address = document.getElementById("searchTextField").value
        setQuery(address)

        dispatch(getChurchList(15, 1, address))
            .then(resp => {
                updateList(resp.data)
            })
            .catch(err => {
                setTimeout(() => {
                    setList([])
                    setLoading(false)
                }, 1000)
            })
    }


    const handleSearch = value => {
        setQuery(value)

        dispatch(getChurchList(15, 1, value))
            .then(resp => {
                updateList(resp.data)
            })
            .catch(err => {
                setTimeout(() => {
                    setList([])
                    setLoading(false)
                }, 1000)
            })
    }

    return (
        <>
            <Container
                css={css`
          position: relative;
        `}
            >
                <form
                    onSubmit={doSearch}
                    css={animation(exiting ? "exiting" : "", "fade")}
                >
                    <SearchInput
                        id="searchTextField"
                        type="text"
                        minLength={2}
                        debounceTimeout={800}
                        placeholder={translation.searchChurch}
                        value={query}
                        onChange={event => handleSearch(event.target.value)}
                    />
                    <SearchButton>
                        {<img src={Search} alt={translation.search}/>}
                    </SearchButton>
                </form>
            </Container>
            <Container>
                {hasCoordinates && (
                    <Title css={animation(exiting ? "exiting" : "", "fade")}>
                        {translation.nextToMe}
                    </Title>
                )}
            </Container>
            <Container>
                <CardsGrid>
                    {loading && <Loading role="img"/>}
                    {!loading &&
                        renderItems(list, dispatch, translation, exitAnimation, exiting)}
                </CardsGrid>
            </Container>
        </>
    )
}

export default connect(
    state => ({
        hasCoordinates: !!state.app.coords.latitude,
    }),
    null
)(List)
