/** @jsx jsx */
import { useState } from "react"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { scrollToTop } from "../helpers"
import { routes } from "../locales"
import Layout from "../components/Layout"
import SEO from "../components/seo"

import PageTitle from "../components/PageTitle"
import List from "../components/pages/Churchs/List"
import DonationPower from "../components/pages/Home/DonationPower"

import Background from "../assets/images/background.png"

const Bg = styled.img`
  position: absolute;
  right: 50%;
  z-index: -1;
  transform: translateX(calc(50% - 40px));
  top: -1200px;
`

const Reader = styled(Img)`
  width: 660px;
  height: 408px;

  ${({ theme }) => css`
    ${theme.queries.small} {
      margin: 30px auto 0;
      width: 310px;
      height: 191px;
    }
  `}
`

const animation = (exiting, type) => css`
  opacity: 1;
  transition: ease-in-out 500ms;

  ${exiting &&
    css`
    ${type === "left" &&
      css`
        transform: translateX(-25%);
        opacity: 0;
      `}
    ${type === "right" &&
      css`
        transform: translateX(25%);
        opacity: 0;
      `}
    ${type === "fade" &&
      css`
        opacity: 0;
      `}
  `}
`

const ChurchPage = ({ pageContext: translation }) => {
  const [exiting, setExiting] = useState(false)

  const exitAnimation = (exit, node) => {
    scrollToTop()
    setExiting(true)
  }

  const data = useStaticQuery(graphql`
    query {
      pt: file(relativePath: { eq: "leitor.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      en: file(relativePath: { eq: "leitor-en.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      es: file(relativePath: { eq: "leitor-es.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />
      <div
        css={css`
          position: relative;
        `}
      >
        <Bg src={Background} alt="" css={animation(exiting, "fade")} />

        <List
          translation={translation}
          exiting={exiting}
          exitAnimation={exitAnimation}
        />
        <DonationPower fontDefault={true} translation={translation} />
      </div>
    </Layout>
  )
}

export default ChurchPage
